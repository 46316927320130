var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mb-11"},[_c('nav',{attrs:{"id":"navbar"}},[_c('div',{staticClass:"flex px-6 main-nav justify-between items-center"},[_c('div',{staticClass:"flex items-center justify-between q-nav-w",staticStyle:{"width":"100%"}},[_c('ul',{staticClass:"w-full flex justify-around; mx-auto lg:text-lg text-sm flex-row-reverse",staticStyle:{}},_vm._l((_vm.navItems),function(item,i){return _c('a',{key:i,class:{
              active2: item.link == _vm.$route.hash || item.isActive == true,
            },staticStyle:{"color":"#64748b"},attrs:{"href":item.link,"name":item.name,"target":item.target},on:{"click":function($event){return _vm.makeActive(item)}}},[_c('li',{staticClass:"relative flex",on:{"mouseover":function($event){return _vm.listProducts(item)}}},[(item.id == '4')?_c('img',{staticClass:"mx-2",attrs:{"src":"/images/primary-shape2.svg","alt":""}}):_vm._e(),_c('div',{staticClass:"mx-6"},[_vm._v(_vm._s(item.name))])]),(item.id == '4' && _vm.products)?_c('div',{staticClass:"custom-list",on:{"mouseleave":function($event){_vm.products = false}}},[_vm._m(0,true),_vm._m(1,true),_vm._m(2,true)]):_vm._e()])}),0)]),_vm._m(3)]),_c('div',{staticClass:"nav-small2 w-full py-6"},[_c('div',{staticClass:"flex justify-around px-11 items-center"},[_c('div',{staticClass:"z-50",staticStyle:{"width":"35px","height":"35px","cursor":"pointer"},on:{"click":function($event){_vm.open = !_vm.open}}},[_c('img',{attrs:{"src":"/images/list.png","alt":""}})]),_vm._m(4)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],staticClass:"absolute z-30 w-full bg-white py-3",staticStyle:{"box-shadow":"0px 4px 4px rgba(0, 0, 0, 0.25)"}},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex justify-between w-full py-6 px-3",staticStyle:{"color":"#64748b"}},[_c('div',{staticClass:"w-3/4 justify-between mx-auto"},[_c('ul',{staticClass:"w-ul-nav"},_vm._l((_vm.navItems),function(item,i){return _c('a',{key:i,staticStyle:{"width":"fit-content","color":"#64748b"},attrs:{"href":item.link,"name":item.name,"target":item.target},on:{"click":function($event){return _vm.makeActive(item)}}},[_c('li',{staticClass:"mb-8 lpx-11 relative flex",class:{
                    active4: item.link == _vm.$route.hash,
                  },staticStyle:{"width":"fit-content"},on:{"click":function($event){return _vm.listProducts2(item)}}},[(item.id == '4')?_c('img',{staticClass:"mx-2",attrs:{"src":"/images/primary-shape2.svg","alt":""}}):_vm._e(),_vm._v(" "+_vm._s(item.name)+" "),(_vm.products == true && item.id == '4')?_c('div',{staticClass:"custom-list",staticStyle:{"z-index":"999"}},[_vm._m(5,true),_vm._m(6,true),_vm._m(7,true)]):_vm._e()])])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://lms.alolo.sa/","target":"_blank"}},[_c('p',{staticClass:"hover:bg-primary hover:text-white px-2"},[_vm._v(" نظام ادارة التعليم ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://ct.alolo.sa/","target":"_blank"}},[_c('p',{staticClass:"hover:bg-primary hover:text-white px-2"},[_vm._v(" مقياس التفكير الحاسوبي ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://alolo.sa/marketing/2.pdf","target":"_blank"}},[_c('p',{staticClass:"hover:bg-primary hover:text-white px-2"},[_vm._v(" تدريس البرمجة للأطفال ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://alolo.sa/","target":"_blank"}},[_c('div',{staticClass:"px-3 q-logo1",staticStyle:{"width":"200px"}},[_c('img',{staticClass:"mx-auto",attrs:{"src":"/images/Mark.svg","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://alolo.sa/","target":"_blank"}},[_c('div',{staticClass:"p-3 flex ml-auto q-logo1",staticStyle:{"width":"120px !important","border-radius":"11px"}},[_c('img',{staticClass:"mx-auto",attrs:{"src":"/images/Mark.svg","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://lms.alolo.sa/","target":"_blank"}},[_c('p',{staticClass:"hover:bg-primary hover:text-white px-2"},[_vm._v(" نظام ادارة التعليم ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://ct.alolo.sa/","target":"_blank"}},[_c('p',{staticClass:"hover:bg-primary hover:text-white px-2"},[_vm._v(" مقياس التفكير الحاسوبي ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://alolo.sa/marketing/2.pdf","target":"_blank"}},[_c('p',{staticClass:"hover:bg-primary hover:text-white px-2"},[_vm._v(" تدريس البرمجة للأطفال ")])])
}]

export { render, staticRenderFns }