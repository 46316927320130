<template>
  <section class="container mx-auto mt-6">
    <div id="home">
      <p
        class="mx-auto text-primary w-full font-bold text-center header-custom mb-11"
        style="font-size: 54px"
      >
        مقياس العُلو للتفكير الحاسوبي
      </p>
      <!-- <p
        class="mx-auto w-2/5 font-medium mt-14 text-center text-secondary sec-header-custom"
        style="font-size: 18px"
      >
        الريادة في تقديم الاستشارات والخدمات التعليمية والفنية، وفق أفضل
        الممارسات والمعايير المحلية والعالمية.
      </p> -->
    </div>
    <div class="w-full my-6">
      <div id="goal" class="flex mx-auto sm:px-11">
        <!-- <img src="/images/Image.svg" alt="" class="mx-auto my-6" /> -->
        <iframe
          id="player"
          style="border-radius: 40px"
          type="text/html"
          :width="widthVideo"
          class="hightVideo"
          :src="`https://www.youtube.com/embed/${extractYoutubeId(
            'https://youtu.be/FOxaxCxPpF4'
          )}?enablejsapi=1&modestbranding=0&autohide=1&showinfo=0&rel=0&controls=0&autoplay88=1&loop=1&playlist=${extractYoutubeId(
            'https://youtu.be/FOxaxCxPpF4'
          )}&autopause=1`"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;autoplay88;"
          frameborder="0"
        >
        </iframe>
      </div>
      <div
        id="whyUs"
        class="md:flex-row flex flex-col-reverse justify-between my-7"
      >
        <div
          class="text-right flex flex-col justify-center md:w-1/2 w-11/12 mx-auto"
        >
          <div class="w-full flex flex-col mx-auto">
            <div
              class="font-extrabold text-primary about-title"
              style="font-size: 40px"
            >
              عن المقياس
            </div>
            <div
              class="ml-auto font-medium mt-6 text-right text-secondary about-custom"
              style="font-size: 20px; line-height: 4.5rem"
            >
              هو أول مقياس رقمي لمهارات التفكير الحاسوبي , تم تطويره بالكامل
              بقدرات سعودية , ليتناسب مع البيئة والثقافة العربية معتمدًا في ذلك
              على النظريات العلمية , وآخر ما توصلت له الدراسات والأبحاث
            </div>
          </div>
        </div>
        <div class="sm:w-2/5 w-3/4 mx-auto w-h-cu">
          <img
            src="/images/Image(1).svg"
            alt=""
            class="mx-auto my-6 about-img"
          />
        </div>
      </div>
      <div class="md:flex-row flex flex-col justify-between md:mt-0 my-16">
        <div class="sm:w-2/5 w-3/4 mx-auto">
          <img
            src="/images/Image(2).svg"
            alt=""
            class="mx-auto my-6 about-img"
          />
        </div>
        <div
          class="text-right flex flex-col justify-center md:w-1/2 w-11/12 mx-auto"
        >
          <div class="w-full flex flex-col mx-auto">
            <div
              class="font-extrabold text-primary about-title"
              style="font-size: 40px"
            >
              ما المقصود بالتفكير الحاسوبي
            </div>
            <div
              class="ml-auto font-medium mt-6 text-right text-secondary about-custom"
              style="font-size: 20px; line-height: 3.5rem"
            >
              هو مجموعة من مهارات التفكير العليا الأساسية التي ينبغي توفرها لدى
              الجميع , حيث تساعد على حل المشكلات , تصميم الأنظمة , فهم السلوك
              البشري , وذلك بالاعتماد على المفاهيم الأساسية لعلوم الحاسب الآلي .
            </div>
          </div>
        </div>
      </div>
      <div class="my-6">
        <p
          class="text-center text-primary font-bold my-2 content-se"
          style="font-size: 42px"
        >
          محتوياته
        </p>
        <p
          class="font-medium text-center text-secondary mb-10 content-se-desc"
          style="font-size: 18px"
        >
          يتكون المقياس من المهارات الفرعية التالية
        </p>
        <div class="sm:flex justify-around flex-wrap">
          <div
            v-for="(item, i) in items3"
            :key="i"
            class="w-1/6 text-center sec-5-custom mb-6 mx-auto sm:mx-0"
          >
            <div style="width: 100%; height: 100%">
              <img :src="item.img" alt="" class="" style="" />
            </div>
            <div class="text-lg font-bold">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="my-24">
        <p
          class="text-center text-primary font-bold my-3 gools"
          style="font-size: 42px"
        >
          أغراض استخدام المقياس
        </p>
        <div class="sm:flex justify-around">
          <div
            v-for="(item, i) in items"
            :key="i"
            class="sm:w-1/5 w-full text-secondary text-center"
          >
            <img src="/images/primary-shape.svg" alt="" class="mx-auto my-6" />
            {{ item }}
          </div>
        </div>
      </div>
      <div class="md:flex-row flex flex-col justify-between">
        <div class="sm:w-2/5 w-3/4 mx-auto">
          <img
            src="/images/Image(3).svg"
            alt=""
            class="mx-auto my-6 object-contain about-img"
          />
        </div>
        <div class="text-right flex flex-col justify-center md:w-1/2 w-11/12">
          <div class="w-full flex flex-col mx-auto">
            <div
              class="font-extrabold text-primary about-title"
              style="font-size: 40px"
            >
              الفئة المستهدفة
            </div>
            <div
              class="ml-auto font-medium mt-6 text-right text-secondary about-custom"
              style="font-size: 20px; line-height: 4.5rem"
            >
              يمكن استخدام المقياس لكافة الفئات العمرية , كما لا يشترط وجود
              خلفية معرفية في مجال علوم الحاسوب
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="w-2/3 flex flex-col mx-auto my-24 info-custom">
        <div
          class="font-extrabold text-primary text-center my-6"
          style="font-size: 42px"
        >
          التعليمات الإرشادات
        </div>
        <div
          v-for="(item, i) in items2"
          :key="i"
          class="text-secondary flex items-center justify-end px-8 text-right mb-3"
        >
          <div class="mx-3 sm:w-10/12 w-full">
            {{ item }}
          </div>
          <div>
            <img
              src="/images/Icon Placeholder 2.svg"
              alt=""
              class="mx-auto my-3"
              style="width: 48px; height: 48px"
            />
          </div>
        </div>
        <router-link to="/aptitude-test">
          <button class="custom-btn my-11">ابدأ الان</button>
        </router-link>
      </div> -->
      <router-link to="/aptitude-test">
        <button class="custom-btn my-11">ابدأ الان</button>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      items: [
        "قياس الميول نحو تخصصات علوم الحاسوب وفروعه",
        "فرز المرشحين للبرامج في مجال علوم الحاسوب وفروعه",
        "قياس أثر البرامج , والبحوث العلمية التي تهدف لتنمية مهارات التفكير الحاسوبي",
        "استكشاف الموهوبين",
      ],
      items2: [
        ".يتكون الاختبار من 20سؤالًا، مقسمة إلى 4صفحات تحتوي كل صفحة على 5 أسئلة.لكل سؤال عدد 4 خيارات متعددة (أ – ب – ج – د )،  يكون خياراً واحد فقط صحيحاً.",
        "زمن الاختبار هو 45 دقيقة حين البدء",
        "ليس من الضروري أن تجيب على جميع الأسئلة",
        "يمكنك التنقل بين صفحات الاختبار من خلال زر المتابعة أو العودة",
        "في حال الانتهاء من الاختبار اضغط على زر ارسال",
        'إذا كنت بحاجة إلى تكبير أي سؤال لتكبيره، فاضغط على "Ctrl+" على لوحة المفاتيح أو "Ctrl-" لجعله أصغر',
        "قبل بدء الاختبار، أمامك عدد ثلاثة أمثلة توضيحية لطريقة الإجابة حتى تتمكن من التعرف على نفسك بنوع الأسئلة التي ستجدها، والشخصيات التي ستظهر.",
      ],
      items3: [
        { title: "مهارة تصميم الخوارزمية", img: "images/Group 43116.png" },
        { title: "مهارةالتقسيم", img: "images/Group 43119.png" },
        { title: "مهارة التجريد", img: "images/Group 43120.png" },
        { title: "مهارة التعرف على الأنماط", img: "images/Group 43121.png" },
      ],
      widthVideo: "",
      hightVideo: "486px",
    };
  },
  methods: {
    extractYoutubeId(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
  },
  mounted() {
    this.widthVideo = window.screen.width;
    // this.hightVideo = window.screen.height;
  },
};
</script>
<style>
.hightVideo {
  height: 480px !important ;
}
.custom-btn {
  background: #386995;
  color: white;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  padding: 10px 0;
  border-radius: 16px;
  box-shadow: 0px 7px #67bb80;
}
@media (max-width: 1024px) {
  .hightVideo {
    height: 300px !important ;
  }
  .header-custom {
    width: 100% !important;
    font-size: 40px !important;
  }
  .sec-header-custom {
    width: 55% !important;
    margin-top: 1.5rem !important;
  }
  .container {
    max-width: 760px !important;
  }
  .about-custom {
    margin-top: 1.5rem !important;
    font-size: 15px !important;
    line-height: 2.5rem !important;
  }
  .about-img {
    /* max-width: 83% !important; */
  }

  .about-title {
    font-size: 27px !important;
  }
  .sec-5-custom {
    width: 30% !important ;
    margin-bottom: 60px !important;
  }
  .info-custom {
    width: 90% !important;
  }
}
@media (max-width: 768px) {
  .sec-5-custom {
    width: 35% !important ;
    margin-bottom: 60px !important;
  }
}
@media (max-width: 640px) {
  .hightVideo {
    height: 200px !important ;
  }
  .sec-5-custom {
    width: 50% !important ;
    margin-bottom: 60px !important;
  }
  .sec-5-custom img {
    margin-bottom: 25px;
  }
  .about-custom {
    width: 90% !important;
    text-align: right;
  }
  .info-custom .font-extrabold {
    font-size: 45px !important;
  }
  .header-custom {
    font-size: 30px !important;
  }
}
@media (max-width: 480px) {
  .content-se {
    font-size: 30px !important;
  }
  .content-se-desc {
    font-size: 15px !important;
  }
  .header-custom {
    font-size: 30px !important;
  }
  .sec-header-custom {
    font-size: 15px !important;
    width: 70% !important;
  }
  .info-custom {
    width: 100% !important;
  }
  .info-custom .font-extrabold {
    font-size: 30px !important;
  }
  .gools {
    font-size: 30px !important;
  }
}
</style>
