var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"container mx-auto mt-6"},[_vm._m(0),_c('div',{staticClass:"w-full my-6"},[_c('div',{staticClass:"flex mx-auto sm:px-11",attrs:{"id":"goal"}},[_c('iframe',{staticClass:"hightVideo",staticStyle:{"border-radius":"40px"},attrs:{"id":"player","type":"text/html","width":_vm.widthVideo,"src":`https://www.youtube.com/embed/${_vm.extractYoutubeId(
          'https://youtu.be/FOxaxCxPpF4'
        )}?enablejsapi=1&modestbranding=0&autohide=1&showinfo=0&rel=0&controls=0&autoplay88=1&loop=1&playlist=${_vm.extractYoutubeId(
          'https://youtu.be/FOxaxCxPpF4'
        )}&autopause=1`,"allow":"accelerometer; encrypted-media; gyroscope; picture-in-picture;autoplay88;","frameborder":"0"}})]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"my-6"},[_c('p',{staticClass:"text-center text-primary font-bold my-2 content-se",staticStyle:{"font-size":"42px"}},[_vm._v(" محتوياته ")]),_c('p',{staticClass:"font-medium text-center text-secondary mb-10 content-se-desc",staticStyle:{"font-size":"18px"}},[_vm._v(" يتكون المقياس من المهارات الفرعية التالية ")]),_c('div',{staticClass:"sm:flex justify-around flex-wrap"},_vm._l((_vm.items3),function(item,i){return _c('div',{key:i,staticClass:"w-1/6 text-center sec-5-custom mb-6 mx-auto sm:mx-0"},[_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',{staticClass:"text-lg font-bold"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)]),_c('div',{staticClass:"my-24"},[_c('p',{staticClass:"text-center text-primary font-bold my-3 gools",staticStyle:{"font-size":"42px"}},[_vm._v(" أغراض استخدام المقياس ")]),_c('div',{staticClass:"sm:flex justify-around"},_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"sm:w-1/5 w-full text-secondary text-center"},[_c('img',{staticClass:"mx-auto my-6",attrs:{"src":"/images/primary-shape.svg","alt":""}}),_vm._v(" "+_vm._s(item)+" ")])}),0)]),_vm._m(3),_c('router-link',{attrs:{"to":"/aptitude-test"}},[_c('button',{staticClass:"custom-btn my-11"},[_vm._v("ابدأ الان")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home"}},[_c('p',{staticClass:"mx-auto text-primary w-full font-bold text-center header-custom mb-11",staticStyle:{"font-size":"54px"}},[_vm._v(" مقياس العُلو للتفكير الحاسوبي ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:flex-row flex flex-col-reverse justify-between my-7",attrs:{"id":"whyUs"}},[_c('div',{staticClass:"text-right flex flex-col justify-center md:w-1/2 w-11/12 mx-auto"},[_c('div',{staticClass:"w-full flex flex-col mx-auto"},[_c('div',{staticClass:"font-extrabold text-primary about-title",staticStyle:{"font-size":"40px"}},[_vm._v(" عن المقياس ")]),_c('div',{staticClass:"ml-auto font-medium mt-6 text-right text-secondary about-custom",staticStyle:{"font-size":"20px","line-height":"4.5rem"}},[_vm._v(" هو أول مقياس رقمي لمهارات التفكير الحاسوبي , تم تطويره بالكامل بقدرات سعودية , ليتناسب مع البيئة والثقافة العربية معتمدًا في ذلك على النظريات العلمية , وآخر ما توصلت له الدراسات والأبحاث ")])])]),_c('div',{staticClass:"sm:w-2/5 w-3/4 mx-auto w-h-cu"},[_c('img',{staticClass:"mx-auto my-6 about-img",attrs:{"src":"/images/Image(1).svg","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:flex-row flex flex-col justify-between md:mt-0 my-16"},[_c('div',{staticClass:"sm:w-2/5 w-3/4 mx-auto"},[_c('img',{staticClass:"mx-auto my-6 about-img",attrs:{"src":"/images/Image(2).svg","alt":""}})]),_c('div',{staticClass:"text-right flex flex-col justify-center md:w-1/2 w-11/12 mx-auto"},[_c('div',{staticClass:"w-full flex flex-col mx-auto"},[_c('div',{staticClass:"font-extrabold text-primary about-title",staticStyle:{"font-size":"40px"}},[_vm._v(" ما المقصود بالتفكير الحاسوبي ")]),_c('div',{staticClass:"ml-auto font-medium mt-6 text-right text-secondary about-custom",staticStyle:{"font-size":"20px","line-height":"3.5rem"}},[_vm._v(" هو مجموعة من مهارات التفكير العليا الأساسية التي ينبغي توفرها لدى الجميع , حيث تساعد على حل المشكلات , تصميم الأنظمة , فهم السلوك البشري , وذلك بالاعتماد على المفاهيم الأساسية لعلوم الحاسب الآلي . ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:flex-row flex flex-col justify-between"},[_c('div',{staticClass:"sm:w-2/5 w-3/4 mx-auto"},[_c('img',{staticClass:"mx-auto my-6 object-contain about-img",attrs:{"src":"/images/Image(3).svg","alt":""}})]),_c('div',{staticClass:"text-right flex flex-col justify-center md:w-1/2 w-11/12"},[_c('div',{staticClass:"w-full flex flex-col mx-auto"},[_c('div',{staticClass:"font-extrabold text-primary about-title",staticStyle:{"font-size":"40px"}},[_vm._v(" الفئة المستهدفة ")]),_c('div',{staticClass:"ml-auto font-medium mt-6 text-right text-secondary about-custom",staticStyle:{"font-size":"20px","line-height":"4.5rem"}},[_vm._v(" يمكن استخدام المقياس لكافة الفئات العمرية , كما لا يشترط وجود خلفية معرفية في مجال علوم الحاسوب ")])])])])
}]

export { render, staticRenderFns }