<template>
  <section class="mb-11">
    <nav id="navbar" class="">
      <div class="flex px-6 main-nav justify-between items-center">
        <div
          class="flex items-center justify-between q-nav-w"
          style="width: 100%"
        >
          <!-- <button
            class="px-6 py-3 text-center bg-white primary"
            style="
              font-weight: 400;
              font-size: 16px;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
              border-radius: 16px;
            "
            @click="selectLang()"
          >
            {{ this.$i18n.locale == "ar" ? "English" : "عربي" }}
          </button> -->
          <ul
            class="w-full flex justify-around; mx-auto lg:text-lg text-sm flex-row-reverse"
            style="width"
          >
            <a
              v-for="(item, i) in navItems"
              :key="i"
              :href="item.link"
              :name="item.name"
              :target="item.target"
              :class="{
                active2: item.link == $route.hash || item.isActive == true,
              }"
              @click="makeActive(item)"
              style="color: #64748b"
            >
              <li class="relative flex" @mouseover="listProducts(item)">
                <img
                  v-if="item.id == '4'"
                  src="/images/primary-shape2.svg"
                  alt=""
                  class="mx-2"
                />
                <div class="mx-6">{{ item.name }}</div>
              </li>
              <div
                class="custom-list"
                v-if="item.id == '4' && products"
                @mouseleave="products = false"
              >
                <a href="https://lms.alolo.sa/" target="_blank">
                  <p class="hover:bg-primary hover:text-white px-2">
                    نظام ادارة التعليم
                  </p>
                </a>
                <a href="https://ct.alolo.sa/" target="_blank">
                  <p class="hover:bg-primary hover:text-white px-2">
                    مقياس التفكير الحاسوبي
                  </p>
                </a>

                <a href="https://alolo.sa/marketing/2.pdf" target="_blank">
                  <p class="hover:bg-primary hover:text-white px-2">
                    تدريس البرمجة للأطفال
                  </p>
                </a>
              </div>
            </a>
          </ul>
        </div>
        <!-- <router-link to="/"> -->
        <a href="https://alolo.sa/" target="_blank">
          <div class="px-3 q-logo1" style="width: 200px">
            <img src="/images/Mark.svg" alt="" class="mx-auto" />
          </div>
        </a>

        <!-- </router-link> -->
      </div>

      <div class="nav-small2 w-full py-6">
        <div class="flex justify-around px-11 items-center">
          <div
            class="z-50"
            style="width: 35px; height: 35px; cursor: pointer"
            @click="open = !open"
          >
            <!-- <img src="/images/menu-icon-24.png" alt="" /> -->
            <img src="/images/list.png" alt="" />
          </div>
          <!-- <router-link to="/"> -->
          <a href="https://alolo.sa/" target="_blank">
            <div
              class="p-3 flex ml-auto q-logo1"
              style="width: 120px !important; border-radius: 11px"
            >
              <img src="/images/Mark.svg" alt="" class="mx-auto" />
            </div>
          </a>
          <!-- </router-link> -->
        </div>
      </div>
      <div
        v-show="open"
        class="absolute z-30 w-full bg-white py-3"
        style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        <div class="w-full">
          <div
            class="flex justify-between w-full py-6 px-3"
            style="color: #64748b"
          >
            <div class="w-3/4 justify-between mx-auto">
              <ul class="w-ul-nav">
                <a
                  v-for="(item, i) in navItems"
                  :key="i"
                  :href="item.link"
                  :name="item.name"
                  @click="makeActive(item)"
                  style="width: fit-content; color: #64748b"
                  :target="item.target"
                >
                  <li
                    class="mb-8 lpx-11 relative flex"
                    style="width: fit-content"
                    @click="listProducts2(item)"
                    :class="{
                      active4: item.link == $route.hash,
                    }"
                  >
                    <img
                      v-if="item.id == '4'"
                      src="/images/primary-shape2.svg"
                      alt=""
                      class="mx-2"
                    />
                    {{ item.name }}
                    <div
                      class="custom-list"
                      style="z-index: 999"
                      v-if="products == true && item.id == '4'"
                    >
                      <a href="https://lms.alolo.sa/" target="_blank">
                        <p class="hover:bg-primary hover:text-white px-2">
                          نظام ادارة التعليم
                        </p>
                      </a>

                      <a href="https://ct.alolo.sa/" target="_blank">
                        <p class="hover:bg-primary hover:text-white px-2">
                          مقياس التفكير الحاسوبي
                        </p>
                      </a>

                      <a
                        href="https://alolo.sa/marketing/2.pdf"
                        target="_blank"
                      >
                        <p class="hover:bg-primary hover:text-white px-2">
                          تدريس البرمجة للأطفال
                        </p>
                      </a>
                    </div>
                  </li>
                </a>
              </ul>
            </div>
          </div>
          <!-- <button
            class="px-6 py-3 text-center bg-white primary"
            style="
              font-weight: 400;
              font-size: 16px;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
              border-radius: 16px;
            "
            @click="selectLang()"
          >
            {{ this.$i18n.locale == "ar" ? "English" : "عربي" }}
          </button> -->
        </div>
      </div>
    </nav>
  </section>
</template>
<script>
export default {
  data() {
    return {
      open: false,
      navItems: [
        {
          name: this.$t("home"),
          link: "/",
          isActive: true,
          id: "1",
          target: "_top",
        },
        // {
        //   name: this.$t("ourMission"),
        //   link: "https://alolo.sa#mse",
        //   isActive: false,
        //   id: "2",
        //   target: "_blank",
        // },
        // {
        //   name: this.$t("whyUs"),
        //   link: "https://alolo.sa#why-us",
        //   isActive: false,
        //   id: "3",
        //   target: "_blank",
        // },
        {
          name: this.$t("ourProducts"),
          link: "#",
          isActive: false,
          target: "_top",
          id: "4",
        },
        // {
        //   name: this.$t("teamAchievements"),
        //   link: "https://alolo.sa#wins",
        //   isActive: false,
        //   id: "5",
        //   target: "_blank",
        // },

        {
          name: this.$t("contact"),
          link: "mailto:${info@alolo.sa}",
          isActive: false,
          id: "6",
          target: "_blank",
        },
      ],
      products: false,
    };
  },
  methods: {
    listProducts2(item) {
      if (item.id == "4") {
        this.products = !this.products;
        console.log("this.products", this.products);
      }
    },
    listProducts(item) {
      if (item.id == "4") {
        this.products = true;
      }
    },
    makeActive(item) {
      // this.$router.push(item.link);
      if (item.id == "4") {
        this.products = true;
      } else {
        this.products = false;
      }
      for (let index = 0; index < this.navItems.length; index++) {
        const element = this.navItems[index];
        if (item.id == element.id) {
          element.isActive = true;
        } else {
          element.isActive = false;
        }
      }
    },
    selectLang() {
      if (this.$i18n.locale == "ar") {
        localStorage.setItem("lang", "en");
        window.location.reload();
      } else {
        localStorage.setItem("lang", "ar");
        window.location.reload();
      }
    },
  },
};
</script>
<style>
.nav-small2 {
  display: none !important;
  /* border: 2px solid #386995; */
  width: 100%;
  border-radius: 18px;
}
@media (max-width: 1024px) {
  .q-nav-w {
    width: 100% !important;
  }
  .q-nav-w button {
    margin-left: 1px;
    margin-right: 1px;
    height: 35px !important;
    width: 120px !important;
  }
  .q-logo1 {
    width: 160px !important;
  }
  .q-navItems {
    width: 70% !important;
  }
}
@media (max-width: 769px) {
  .main-nav {
    display: none !important;
  }
  .nav-small2 {
    display: block !important;
    transition: all 0.15s ease;
  }
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  position: relative;
  color: black;
  transition: all 0.15s ease;
}

a.active2:after {
  position: absolute;
  content: "";
  height: 2px;
  bottom: -11px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #386995;
  transition: all 0.15s ease;
}

a.active2 li {
  color: #67bb80 !important;
}
.w-ul-nav a.active2:after {
  width: 50% !important;

  width: 26% !important;
}
.active4 {
  border-bottom: solid 2px;
  color: #386995 !important;
}
.custom-list {
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  text-align: end;
  position: absolute;
  background: white;
  width: 205px;
  top: 35px;
  left: -53px;
  color: black;
}
.custom-list p {
  font-size: 15px;
}
#navbar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
@media (max-width: 992px) {
  .q-logo1 {
    width: 120px !important;
  }
  .q-nav-w {
  }
}
</style>
