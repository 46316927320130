import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./plugins/axios";
import "./plugins";
import "./assets/style/app.scss";
import i18n from "./i18n";
import VueSocialSharing from "vue-social-sharing";
import ProgressBar from "vuejs-progress-bar";
Vue.use(ProgressBar);
Vue.use(VueSocialSharing);
import VueStepWizard from "vue-step-wizard";
import "vue-step-wizard/dist/vue-step-wizard.css";
Vue.use(VueStepWizard);
Vue.config.productionTip = false;
Vue.prototype.$axios = api;
import Toasted from "vue-toasted";
import Print from "vue-print-nb";

Vue.use(Print);
Vue.use(Toasted, {
  duration: 1000,
  position: "bottom-right",
  action: {
    text: "Okay",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
