<template>
  <div id="app" :class="this.$i18n.locale !== 'ar' ? 'rtl' : ''">
    <NavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: { NavBar, Footer },
  mounted() {},
};
</script>
<style>
.rtl {
  direction: rtl !important;
}
</style>
